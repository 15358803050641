if (document.querySelector(".gallery-image")) {
    const galleryImages = document.querySelectorAll(".gallery-image");
    const popup = document.querySelector(".img-popup");
    const popupImg = popup.querySelector("img");
    const blur = document.querySelector(".popup-blur");
    const prevBtn = popup.querySelector(".prev-btn");
    const nextBtn = popup.querySelector(".next-btn");
    const count = popup.querySelector(".count");
    const body = document.querySelector("body");

    let currentImageIndex = 0;
    let isPopupOpen = false;

    function openPopup(image) {
        const images = Array.from(galleryImages);
        currentImageIndex = images.indexOf(image);

        const imageSrc = image.querySelector("img").getAttribute("src");
        popup.classList.add("active");
        body.classList.add("active");
        popupImg.setAttribute("src", imageSrc);
        blur.classList.add("active");
        isPopupOpen = true;
        blur.removeEventListener("click", closePopup);

        updateNavigation();
    }

    function closePopup() {
        popup.classList.remove("active");
        blur.classList.remove("active");
        body.classList.remove("active");
        isPopupOpen = false;
        blur.addEventListener("click", closePopup);
    }

    function showPrevImage() {
        currentImageIndex--;
        if (currentImageIndex < 0) {
            currentImageIndex = galleryImages.length - 1;
        }
        const imageSrc = galleryImages[currentImageIndex]
            .querySelector("img")
            .getAttribute("src");
        popupImg.setAttribute("src", imageSrc);
        updateNavigation();
    }

    function showNextImage() {
        currentImageIndex++;
        if (currentImageIndex >= galleryImages.length) {
            currentImageIndex = 0;
        }
        const imageSrc = galleryImages[currentImageIndex]
            .querySelector("img")
            .getAttribute("src");
        popupImg.setAttribute("src", imageSrc);
        updateNavigation();
    }

    function updateNavigation() {
        count.textContent = `${currentImageIndex + 1}/${galleryImages.length}`;
    }

    galleryImages.forEach((image) => {
        image.addEventListener("click", (event) => {
            if (!isPopupOpen) {
                openPopup(image);
            }
            event.stopPropagation();
        });
    });

    popup.addEventListener("click", (event) => {
        if (event.target === popup) {
            closePopup();
        } else if (event.target.classList.contains("close-btn")) {
            closePopup();
        }
    });

    blur.addEventListener("click", (event) => {
        if (isPopupOpen && event.target.classList.contains("popup-blur")) {
            closePopup();
        }
    });

    prevBtn.addEventListener("click", showPrevImage);
    nextBtn.addEventListener("click", showNextImage);

    let touchStartX = 0;
    let touchEndX = 0;

    popupImg.addEventListener("touchstart", (event) => {
        touchStartX = event.changedTouches[0].clientX;
    });

    popupImg.addEventListener("touchend", (event) => {
        touchEndX = event.changedTouches[0].clientX;
        handleSwipeGesture();
    });

    function handleSwipeGesture() {
        const threshold = 50;
        const gestureDistance = touchEndX - touchStartX;
        if (gestureDistance > threshold) {
            showPrevImage();
        } else if (gestureDistance < -threshold) {
            showNextImage();
        }
    }
}
