// Wähle alle Tabellenzellen aus
var cells = document.getElementsByTagName("td");

// Schleife über alle Zellen und überprüfe, ob sie leer sind
for (var i = 0; i < cells.length; i++) {
    if (cells[i].textContent.trim() === "") {
        // Weise der Zelle die Klasse "empty" zu
        cells[i].classList.add("empty");
    }
}
