(function () {
    //!!!Build Form!!!//
    // Build Select-Dropdown
    document.querySelectorAll("form select").forEach((item) => {
        const select = item;
        const selectName = select.getAttribute("name");
        const selectID = select.getAttribute("id");
        const label =
            select.parentElement.parentElement.querySelector("label").innerText;
        const options = select.querySelectorAll("option"); //options can contain more than one element

        // //Taking all options right away into a string
        // let optionContent = "";
        // options.forEach((item) => {
        //     optionContent += `<div class="selectOption" data-value="${item.value}">${item.innerText}</div>`;
        // });

        // select.parentElement.innerHTML = `
        //     <div class="selectContainer">
        //         <input class="selectInput" id="${selectID}" name="${selectName}" type="hidden" value="" />
        //         <div class="currentOption">
        //             Bitte wählen ...
        //         </div>
        //         <div class="selectContainerInner">${optionContent}</div>
        //     </div>
        // `;
    });

    // Set Classes for each Input-Group
    document.querySelectorAll('input[id*="plz"]').forEach((item) => {
        item.closest(".clearfix").classList.add("w25");
    });

    document
        .querySelectorAll(
            'input[id*="firstname"], input[id*="lastname"], input[id*="subject"], input[id*="mail"], input[id*="phone"], input[id*="position"], input[id*="company"], input[id*="date"]'
        )
        .forEach((item) => {
            item.closest(".clearfix").classList.add("w50");
        });

    document.querySelectorAll('input[id*="city"]').forEach((item) => {
        item.closest(".clearfix").classList.add("w75");
    });

    document.querySelectorAll('input[id*="privacy-policy"]').forEach((item) => {
        item.closest(".clearfix").classList.add("checkbox");
    });

    //Setting Extra-Class for input-box of ul.input-list-items
    document.querySelectorAll("ul.inputs-list").forEach((item) => {
        item.parentElement.classList.add("ul-input");
    });

    //Setting Extra-Class for checkbox-input
    document.querySelectorAll('input[type*="checkbox"]').forEach((item) => {
        item.closest("div.input").classList.add("checkbox-input");
    });

    // Change Button-Text
    document.querySelectorAll(".actions li.submit button").forEach((item) => {
        item.innerText = "Senden";
    });

    document
        .querySelectorAll('form[id*="contact-form"] .actions li.submit button')
        .forEach((item) => {
            item.innerText = "Kontaktieren";
        });

    document
        .querySelectorAll(
            'form[id*="subscribe-form"] .actions li.submit button'
        )
        .forEach((item) => {
            item.innerText = "Anmelden";
        });

    document
        .querySelectorAll('form[id*="member-form"] .actions li.submit button')
        .forEach((item) => {
            item.innerText = "Mitgliedsantrag absenden";
        });

    //!!!Control Form!!!//
    // Toggle SelectContainer
    const selectContainers = document.querySelectorAll(".selectContainer");
    selectContainers.forEach((item) => {
        item.addEventListener("click", (event) => {
            const currentContainer = event.target;

            // remove active class for all select containers, but not for the current clicked select container
            removeActive(selectContainers, currentContainer);

            // set active class for clicked select container
            item.classList.toggle("active");
        });
    });

    const removeActive = (selects, currentSelect) => {
        selects.forEach((item) => {
            if (item !== currentSelect.closest(".selectContainer")) {
                item.classList.remove("active");
            }
        });
    };

    // Choose Select option
    const selectOptions = document.querySelectorAll(".selectOption");
    selectOptions.forEach((item) => {
        item.addEventListener("click", (event) => {
            const option = event.target;
            const label = option.textContent;
            const value = option.dataset.value;
            const currentOption = option
                .closest(".selectContainer")
                .querySelector(".currentOption");
            const input = option
                .closest(".selectContainer")
                .querySelector(".selectInput");

            currentOption.textContent = label;
            input.value = value;
        });
    });

    // Toggle Active Class on ul.inputs-list li
    document.querySelectorAll("ul.inputs-list").forEach((list) => {
        const labels = list.querySelectorAll("li label");
        labels.forEach((item) => {
            item.addEventListener("click", (event) => {
                const currentLabel = event.target;

                removeActive(labels, currentLabel);

                item.classList.add("active");
            });
        });

        const removeActive = (labels, currentLabel) => {
            labels.forEach((item) => {
                if (item !== currentLabel.closest("label")) {
                    item.classList.remove("active");
                }
            });
        };
    });

    //Get AddOn checked
    // Hole das Label-Element
    const label = document.querySelector("label.add-on");

    // Hole das Input-Element
    const input = label.querySelector("input.add-on");

    // Füge das Klickereignis zum Label-Element hinzu
    label.addEventListener("click", () => {
        // Wenn das Label und Input die Klasse "checked" haben
        if (
            label.classList.contains("checked") &&
            input.classList.contains("checked")
        ) {
            // Entferne die "checked"-Klasse vom Label
            label.classList.remove("checked");
            // Entferne die "checked"-Klasse vom Input
            input.classList.remove("checked");
        } else {
            // Füge die "checked"-Klasse zum Label hinzu
            label.classList.add("checked");
            // Füge die "checked"-Klasse zum Input hinzu
            input.classList.add("checked");
        }
    });

    //Adjust height of textarea depending on content
    document.querySelectorAll("textarea").forEach((item) => {
        item.addEventListener("input", () => {
            item.style.height = "";
            item.style.height = item.scrollHeight + "px";
        });
    });
})();
