document.addEventListener("DOMContentLoaded", function () {
    var heroBanner = document.querySelector(".hero-banner");
    var header = document.querySelector("header");

    // Überprüfen, ob die aktuelle Seite ein Element mit der Klasse "hero-banner" enthält
    if (heroBanner) {
        // Erhalte die Höhe des "hero-banner" Elements
        var heroBannerHeight = heroBanner.offsetHeight;

        // Füge eine "scroll" Event-Listener hinzu
        window.addEventListener("scroll", function () {
            // Wenn die Position des Headers größer als die Höhe des "hero-banner" Elements minus 100px ist, füge die Klasse "fade-in" zum Header hinzu
            if (window.pageYOffset > heroBannerHeight - 80) {
                header.classList.add("fade-in");
            }
            // Andernfalls entferne die Klasse "fade-in" vom Header
            else {
                header.classList.remove("fade-in");
            }
        });
    }
    // Andernfalls wird der Header dauerhaft die Klasse "fade-in" haben
    else {
        header.classList.add("fade-in");
    }
});
