(function () {
    if (document.querySelector(".match-list")) {
        const buttonKidscupu12 = document.querySelector(".button.kidscupu12");
        const tableItemsKidscupu12 = document.querySelectorAll(
            ".table-item.kidscupu12"
        );
        const buttonHerren1 = document.querySelector(".button.herren1");
        const tableItemsHerren1 = document.querySelectorAll(
            ".table-item.herren1"
        );
        const buttonHerren60 = document.querySelector(".button.herren60");
        const tableItemsHerren60 = document.querySelectorAll(
            ".table-item.herren60"
        );
        const buttonHobbymixed = document.querySelector(".button.hobbymixed");
        const tableItemsHobbymixed = document.querySelectorAll(
            ".table-item.hobbymixed"
        );
        const buttonDamen1 = document.querySelector(".button.damen1");
        const tableItemsDamen1 =
            document.querySelectorAll(".table-item.damen1");
        const buttonDamen30 = document.querySelector(".button.damen30");
        const tableItemsDamen30 = document.querySelectorAll(
            ".table-item.damen30"
        );
        const buttonDamen50 = document.querySelector(".button.damen50");
        const tableItemsDamen50 = document.querySelectorAll(
            ".table-item.damen50"
        );
        const buttonAlle = document.querySelector(".button.alle");
        const tableItemsAlle = document.querySelectorAll(".table-item");

        const toggleClass = (tableItems) => {
            tableItemsAlle.forEach((tableItem) =>
                tableItem.classList.add("hidden")
            );
            tableItems.forEach((tableItem) =>
                tableItem.classList.remove("hidden")
            );
        };

        const setActiveFilterButton = (activeButton) => {
            const filterButtons = document.querySelectorAll(".button");
            filterButtons.forEach((button) => {
                button.classList.remove("active");
            });
            activeButton.classList.add("active");
        };

        const filterButtonClickHandler = (button, tableItems) => {
            button.addEventListener("click", () => {
                toggleClass(tableItems);
                setActiveFilterButton(button);
            });
        };

        filterButtonClickHandler(buttonKidscupu12, tableItemsKidscupu12);
        filterButtonClickHandler(buttonHerren1, tableItemsHerren1);
        filterButtonClickHandler(buttonHerren60, tableItemsHerren60);
        filterButtonClickHandler(buttonHobbymixed, tableItemsHobbymixed);
        filterButtonClickHandler(buttonDamen1, tableItemsDamen1);
        filterButtonClickHandler(buttonDamen30, tableItemsDamen30);
        filterButtonClickHandler(buttonDamen50, tableItemsDamen50);
        filterButtonClickHandler(buttonAlle, tableItemsAlle);
    }
})();
