// select all table items
const tableItems = document.querySelectorAll(".table-item");

// add click event listener to each table item
tableItems.forEach((item) => {
    item.addEventListener("click", () => {
        // check if clicked table item already has active class
        const isActive = item.classList.contains("active");

        // remove active class from all other table items
        tableItems.forEach((item) => {
            item.classList.remove("active");
        });

        // add active class to clicked table item if it wasn't already active
        if (!isActive) {
            item.classList.add("active");
        }
    });
});
