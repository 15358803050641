(function () {
    var menuButton = document.querySelector(".menu");
    var header = document.querySelector("header");
    var hamburgerIcon = document.querySelector(".hamburger-icon");
    var headerMenu = document.querySelector(".nav-list");
    var navPoint = document.querySelectorAll(".nav-list a");
    var body = document.querySelector("body");

    menuButton.addEventListener("click", (event) => {
        header.classList.toggle("active");
        body.classList.toggle("active");
        hamburgerIcon.classList.toggle("active");
        headerMenu.classList.toggle("active");
    });

    window.addEventListener("click", (event) => {
        if (event.target != headerMenu && event.target != menuButton) {
            hamburgerIcon.classList.remove("active");
            headerMenu.classList.remove("active");
            header.classList.remove("active");
        }
    });

    navPoint.forEach((item) => {
        item.addEventListener("click", (event) => {
            hamburgerIcon.classList.remove("active");
            document.querySelector(".nav-list").classList.remove("active");
        });
    });
})();
